import React from 'react'
import { Breadcrumbs, Hero, Page, Solutions } from '@components'

const meta = {
  title: 'Rješenja | Protupožarna zaštita - FSB d.o.o.',
  description: 'U svojoj ponudi nudimo izvođenje rješenja pasivne protupožarne zaštite.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/rjesenja/', name: 'Rješenja'},
      ]}
    />
    <Hero
      slim
      description={meta.description}
      subtitle="Rješenja pasivne protupožarne zaštite"
      title="Rješenja"
    />
    <Solutions />
  </Page>
)

export default Homepage